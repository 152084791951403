import * as React from 'react';
import { useState } from 'react';
import { Button } from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import { IQuinoMetaPanelExtensionProps, ObjectBookmark, useService } from '@quino/ui';
import { ILogger, QuinoCoreServiceSymbols } from '@quino/core';
import { ICustomActionService } from '../../api/ICustomActionService';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { ICustomAction } from '../../customActions/ICustomAction';
import { EntityActionEmphasis } from '../../meta/EntityAction/EntityActionEmphasis';

interface IOwnProps {
    entityAction: ICustomAction;
    onEntityActionExecuted?: (actionName?: string) => void;
}

export const PerformaMetaPanelEntityActionButton: React.FunctionComponent<
    IQuinoMetaPanelExtensionProps & IOwnProps
> = (props) => {
    const customActionService = useService<ICustomActionService>(
        SHARED_SERVICE_IDENTIFIER.ICUSTOMACTIONSERVICE
    );
    const logger = useService<ILogger>(QuinoCoreServiceSymbols.ILogger);
    const { bookmark, entityAction, onEntityActionExecuted } = props;

    const [isExecuting, setIsExecuting] = useState(false);

    if (bookmark instanceof ObjectBookmark) {
        const { metaClass, primaryKey } = bookmark.genericObject;

        const executeAction = async () => {
            if (primaryKey) {
                setIsExecuting(true);
                try {
                    await customActionService.executeCustomActionAsync(
                        entityAction.name,
                        metaClass,
                        primaryKey
                    );
                    setIsExecuting(false);
                    onEntityActionExecuted && onEntityActionExecuted(entityAction.name);
                } catch (e) {
                    logger.logWarn(
                        `The entity Action [${JSON.stringify(
                            entityAction
                        )}] did execute with errors [${JSON.stringify(e)}]`
                    );
                    setIsExecuting(false);
                    onEntityActionExecuted && onEntityActionExecuted();
                }
            } else {
                logger.logWarn(
                    `Could not execute entity Action [${JSON.stringify(
                        entityAction
                    )}] because no Primary key was defined`
                );
            }
        };

        return (
            <>
                <Button
                    type={'default'}
                    stylingMode={
                        entityAction.style.includes(EntityActionEmphasis.High)
                            ? 'contained'
                            : entityAction.style.includes(EntityActionEmphasis.Low)
                                ? 'text'
                                : 'outlined'
                    }
                    text={entityAction.caption}
                    disabled={
                        isExecuting ||
                        (!entityAction.style.includes(EntityActionEmphasis.Enabled) &&
                            (entityAction.style.includes(EntityActionEmphasis.Disabled) ||
                                entityAction.disabled))
                    }
                    onClick={executeAction}
                />
                <LoadPanel visible={isExecuting} />
            </>
        );
    }

    return <div />;
};

import React from 'react';
import { lazyInject } from '@quino/core';
import { QuinoUIServiceSymbols, IMarkdownConverter } from '@quino/ui';
import { CSSProperties } from 'react-jss';

type TStyles = {
    markdownWrapper: string;
};
const styles: TStyles = require('./Markdown.less');

interface IProps {
    children?: string;
    style?: CSSProperties
}

export class Markdown extends React.Component<IProps> {
    @lazyInject(QuinoUIServiceSymbols.IMarkdownConverter)
    private markdownConverter: IMarkdownConverter;

    render() {
        const convertedMarkdown = this.markdownConverter.convertToHtml(this.props.children || '');

        return (
            <div
                className={styles.markdownWrapper}
                style={this.props.style}
                dangerouslySetInnerHTML={{ __html: convertedMarkdown }}
            />
        );
    }
}

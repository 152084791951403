import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import {
    IBookmark,
    IBookmarkFactory,
    IQuinoMetaPanelActions,
    ListBookmark,
    LoadingPanel,
    ObjectBookmark,
    QuinoMetaPanel,
    QuinoUIServiceSymbols,
    useOnBookmarkAnyEvent,
    useOnBookmarkSavedEvent,
    useRerender,
} from '@quino/ui';
import {
    IDataService,
    IGenericObject,
    IMetaLayout,
    LayoutType,
    NewGenericObjectPrimaryKey,
    QuinoCoreServiceSymbols,
} from '@quino/core';
import { RouteComponentProps, withRouter } from 'react-router';
import { ILocationService, IRouteHelper } from '../../navigation';
import { IDataBrowserRouteProps } from './IDataBrowserRouteProps';
import { SHARED_SERVICE_IDENTIFIER } from '../../ioc/sharedIdentifiers';
import { AuthorizationContext } from '../../contexts/authorizationContext/AuthorizationContext';
import { TListLayoutFilter } from './TListLayoutFilter.js';
import { useService } from '../../ioc/hook/useService';
import { CrmContext } from '../../contexts/crmContext/CrmContext';
import { DataBrowserContext } from './DataBrowserContext';
import { Breadcrumbs } from './Breadcrumbs';
import { ILayoutCatalogManager } from './ILayoutCatalogManager';
import { SelectedView } from './DataBrowserReducer';
import { ICustomAction, IEntityActionsExtractor } from '../../customActions';
import { SiteMapContext } from '../SiteMapContext';
import { useDispatch, useSelector } from 'react-redux';
import { getListStateFor, listStateActions } from '../../redux/listStates/store';
import { PerformaMetaPanelHeader } from './PerformaMetaPanelHeader';
import { PerformaMetaPanelFooter } from './PerformaMetaPanelFooter';
import { ILayoutCatalog } from './ILayoutCatalog';
import { ErrorMessage } from '../Util/ErrorMessage';
import { useI18n } from '../../lang/useI18n';
import { PopupButtonBar } from '../Util/Devextreme/PopupButtonBar';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import { useWeakValidationMetaClasses } from '../../configuration/hook/useWeakValidationMetaClasses';
import { NEW_RECORD_URL_SEGMENT } from '../../navigation/RouteHelper';
import { TTranslationKey } from '../../lang/TranslationKeys';

interface IPopupResult {
    title: string;
    text: string;
}

interface IOwnProps {
    entityName: string;
    defaultListLayoutName?: string;
    defaultDetailLayoutName?: string;
    listLayoutFilter?: TListLayoutFilter;
    forceCreateDisabled?: boolean;
}

interface IProps extends IOwnProps, RouteComponentProps<IDataBrowserRouteProps> {}

const DataBrowserComponent = (props: PropsWithChildren<IProps>) => {
    const {
        entityName,
        defaultListLayoutName,
        defaultDetailLayoutName,
        listLayoutFilter,
        location,
        history,
        forceCreateDisabled,
    } = props;

    const dataService = useService<IDataService>(QuinoCoreServiceSymbols.IDataService);
    const i18n = useI18n();
    const routeHelper = useService<IRouteHelper>(SHARED_SERVICE_IDENTIFIER.IROUTEHELPER);
    const bookmarkFactory = useService<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory);
    const layoutCatalogManager = useService<ILayoutCatalogManager>(
        SHARED_SERVICE_IDENTIFIER.ILAYOUTCATALOGMANAGER
    );
    const entityActionsExtractor = useService<IEntityActionsExtractor>(
        SHARED_SERVICE_IDENTIFIER.IENTITYACTIONSEXTRACTOR
    );
    const quinoMetaPanelActions = useService<IQuinoMetaPanelActions>(
        QuinoUIServiceSymbols.IQuinoMetaPanelActions
    );
    const locationService = useService<ILocationService>(
        SHARED_SERVICE_IDENTIFIER.ILOCATIONSERVICE
    );

    const { activeCrmContext, reloadCrmContextsAsync } = useContext(CrmContext);
    const authorizationContext = useContext(AuthorizationContext);

    if (!activeCrmContext) {
        throw new Error('no CRM context selected in DataBrowser');
    }

    const dispatch = useDispatch();
    const weakValidationMetaClasses = useWeakValidationMetaClasses();

    const { currentSiteMapItem, siteMapContainsCurrentEntity } = useContext(SiteMapContext);
    const { actions: dataBrowserContextActions } = useContext(DataBrowserContext);

    const currentSiteMapPath = currentSiteMapItem && currentSiteMapItem.path;

    const [layoutCatalog, setLayoutCatalog] = useState<ILayoutCatalog>({
        drillDownEnabled: false,
    });

    const [listLayout, setListLayout] = useState<IMetaLayout>();

    const preservedListState = useSelector(getListStateFor(currentSiteMapPath));

    const [bookmark, setBookmark] = useState<IBookmark>();
    const [entityActions, setEntityActions] = useState<ICustomAction[] | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string>();

    const [detailNotFound, setDetailNotFound] = useState<boolean>(false);
    const [noDetailLayoutFound, setNoDetailLayoutFound] = useState<boolean>(false);
    const [noListLayoutFound, setNoListLayoutFound] = useState<boolean>(false);
    const [popupResult, setPopupResult] = useState<IPopupResult>();

    const rerender = useRerender();
    useOnBookmarkSavedEvent(bookmark, rerender);

    const defaultListLayoutFilter = (layout: IMetaLayout) => layout;

    const loadLayoutCatalog = async () => {
        const newLayoutCatalog = await layoutCatalogManager.getLayoutsAsync(entityName);

        const filteredListLayouts = (newLayoutCatalog.listLayouts || []).filter(
            listLayoutFilter || defaultListLayoutFilter
        );

        setLayoutCatalog({
            ...newLayoutCatalog,
            listLayouts: filteredListLayouts,
        });
    };

    useEffect(
        () => {
            loadLayoutCatalog();
        },
        [entityName]
    );

    const setListLayoutByName = (layoutName: string) => {
        if (!layoutCatalog.listLayouts) {
            setErrorMessage(
                i18n.t('literal.CustomLiterals.DataBrowser.ErrorNoListLayoutsAvailableYet')
            );
            return;
        }

        const listLayout = layoutCatalog.listLayouts.find((f) => f.name === layoutName);
        if (!listLayout) {
            setErrorMessage(
                i18n.t('literal.CustomLiterals.DataBrowser.ErrorListLayoutNotFound', {
                    layoutName,
                })
            );
            return;
        }

        setListLayout(listLayout);
    };

    const setDefaultListLayout = () => {
        if (preservedListState || !layoutCatalog.listLayouts || routeHelper.isDetailPage()) {
            return;
        }

        const defaultListLayout = layoutCatalogManager.getDefaultLayout(
            layoutCatalog.listLayouts,
            defaultListLayoutName
        );

        if (!defaultListLayout) {
            setErrorMessage(
                i18n.t('literal.CustomLiterals.DataBrowser.ErrorUnableToDetermineLayoutName')
            );
        }

        setListLayout(defaultListLayout);
    };

    useEffect(setDefaultListLayout, [layoutCatalog.listLayouts, location]);

    const updateObjectBookmarkFromUrl = async () => {
        if (!bookmark && routeHelper.isDetailPage()) {
            const { formLayouts } = await layoutCatalogManager.getLayoutsAsync(entityName);
            const paramsId = routeHelper.getParamsIdFromUrl();
            const defaultLayout = layoutCatalogManager.getDefaultLayout(
                formLayouts || [],
                defaultDetailLayoutName
            );

            if (!defaultLayout) {
                setNoDetailLayoutFound(true);
                return;
            }

            const genericObjectPromise = dataService.getObjectAsync<IGenericObject>(
                entityName,
                paramsId === NEW_RECORD_URL_SEGMENT ? null : paramsId,
                defaultLayout.name
            );

            genericObjectPromise
                .then(async (genericObject: IGenericObject) => {
                    const layoutOverwrite = await layoutCatalogManager.getLayoutForObject(
                        genericObject,
                        formLayouts || [],
                        defaultDetailLayoutName
                    );

                    let object = genericObject;
                    let layout = defaultLayout;
                    if (defaultLayout.name !== layoutOverwrite.name) {
                        layout = layoutOverwrite;
                        object = await dataService.getObjectAsync<IGenericObject>(
                            entityName,
                            paramsId === NEW_RECORD_URL_SEGMENT ? null : paramsId,
                            layoutOverwrite.name
                        );
                    }

                    const objectBookmark = bookmarkFactory.createObject(object, layout);
                    setBookmark(objectBookmark);
                })
                .catch(() => {
                    setDetailNotFound(true);
                });
        }
    };

    useEffect(
        () => {
            updateObjectBookmarkFromUrl();
        },
        [location]
    );

    const updateListBookmarkFromListLayout = async () => {
        if (!routeHelper.isDetailPage() && listLayout) {
            try {
                setBookmark(await bookmarkFactory.createList(entityName, listLayout));
            } catch (e) {
                setNoListLayoutFound(true);
            }
        }
    };

    useEffect(
        () => {
            (async () => {
                await updateListBookmarkFromListLayout();
            })();
        },
        [listLayout, location]
    );

    const updateBreadcrumbs = () => {
        if (bookmark instanceof ListBookmark) {
            dataBrowserContextActions.setListCaption(bookmark.layout.caption);
            dataBrowserContextActions.setSelectedView(SelectedView.List);
        } else if (bookmark instanceof ObjectBookmark) {
            // check if entity name is in SiteMap and extend breadcrumb otherwise
            if (!siteMapContainsCurrentEntity(bookmark.genericObject.metaClass)) {
                const subDetailPath = routeHelper.getUrl(
                    activeCrmContext.context,
                    bookmark.genericObject.metaClass,
                    bookmark.genericObject.primaryKey
                );
                dataBrowserContextActions.addSubDetailEntry({
                    caption: bookmark.genericObject.title,
                    path: subDetailPath,
                });
                dataBrowserContextActions.setSelectedView(SelectedView.SubDetail);
            } else {
                if (listLayout) {
                    dataBrowserContextActions.setListCaption(listLayout.caption);
                }

                if (
                    bookmark.genericObject.primaryKey &&
                    bookmark.genericObject.primaryKey !== NewGenericObjectPrimaryKey
                ) {
                    dataBrowserContextActions.setDetailCaption(bookmark.genericObject.title);
                    const detailPath = routeHelper.getUrl(
                        activeCrmContext.context,
                        bookmark.genericObject.metaClass,
                        bookmark.genericObject.primaryKey
                    );
                    dataBrowserContextActions.setDetailPath(detailPath);
                } else {
                    dataBrowserContextActions.setDetailCaption(
                        i18n.t('literal.CustomLiterals.DataList.New')
                    );
                }
                dataBrowserContextActions.setSelectedView(SelectedView.Detail);
            }
        } else if (currentSiteMapItem) {
            const listPath = routeHelper.getUrl(
                activeCrmContext.context,
                currentSiteMapItem && currentSiteMapItem.path
            );
            dataBrowserContextActions.setListPath(listPath);
            dataBrowserContextActions.setListCaption(
                i18n.t(currentSiteMapItem.translationKey as any)
            );
        }
    };

    const updateUrl = () => {
        if (bookmark instanceof ObjectBookmark) {
            if (
                currentSiteMapItem &&
                activeCrmContext &&
                bookmark.genericObject.primaryKey &&
                bookmark.genericObject.primaryKey !== NewGenericObjectPrimaryKey
            ) {
                const url = routeHelper.getUrl(
                    activeCrmContext.context,
                    currentSiteMapItem && currentSiteMapItem.path,
                    bookmark.genericObject.primaryKey,
                    undefined,
                    false
                );

                if (location.pathname !== url) {
                    if (routeHelper.getParamsIdFromUrl() === NEW_RECORD_URL_SEGMENT) {
                        locationService.navigateToUrl(history, url);
                        updateBreadcrumbs();
                    } else {
                        history.push(url);
                    }
                }
            }
        }
    };

    const updateEntityActions = async () => {
        if (bookmark instanceof ObjectBookmark) {
            let customEntityActions = await entityActionsExtractor.getEntityActionsAsync(
                bookmark.genericObject
            );

            if (weakValidationMetaClasses.includes(bookmark.genericObject.metaClass)) {
                if (bookmark.genericObject.primaryKey !== NewGenericObjectPrimaryKey) {
                    const validationResult = await quinoMetaPanelActions.validate(bookmark);
                    const hasErrors = !(
                        (validationResult.fieldErrors == null ||
                            validationResult.fieldErrors.length === 0) &&
                        (validationResult.generalErrors == null ||
                            validationResult.generalErrors.length === 0)
                    );
                    if (hasErrors) {
                        customEntityActions =
                            customEntityActions &&
                            customEntityActions.map((value) => {
                                value.disabled = true;
                                return value;
                            });
                    }
                }
            }

            if (bookmark.hasChanges()) {
                customEntityActions =
                    customEntityActions &&
                    customEntityActions.map((value) => {
                        value.disabled = true;
                        return value;
                    });
            }

            setEntityActions(customEntityActions);
        } else {
            setEntityActions(undefined);
        }
    };

    useEffect(
        () => {
            updateUrl();
            updateEntityActions();
        },
        [bookmark]
    );

    useOnBookmarkAnyEvent(bookmark, updateEntityActions);

    useEffect(updateBreadcrumbs, [currentSiteMapItem, bookmark]);

    const onNewRecord = () => {
        if (currentSiteMapItem && activeCrmContext) {
            const url = routeHelper.getUrl(
                activeCrmContext.context,
                currentSiteMapItem && currentSiteMapItem.path,
                undefined,
                undefined,
                true
            );
            setBookmark(undefined);
            locationService.navigateToUrl(history, url);
        }
    };

    const { drillDownEnabled, listLayouts } = layoutCatalog;

    const authorization = authorizationContext.getAuthorization(entityName);

    const allowCreate = !forceCreateDisabled && drillDownEnabled && authorization.canCreate();

    const updateLayoutFromPreservedState = () => {
        const layoutName = preservedListState && preservedListState.layoutName;

        if (layoutName && layoutCatalog.listLayouts) {
            setListLayoutByName(layoutName);
        }
    };
    useEffect(updateLayoutFromPreservedState, [preservedListState, layoutCatalog]);

    const updatePreservedStateFromLayout = () => {
        const layoutName = listLayout && listLayout.name;
        if (layoutName && currentSiteMapPath) {
            const action = listStateActions.updateListState(currentSiteMapPath, { layoutName });
            dispatch(action);
        }
    };

    useEffect(updatePreservedStateFromLayout, [listLayout]);

    const onActionExecuted = (actionName?: string) => {
        // shows result popup
        const titleTranslationKey = `literal.CustomLiterals.${actionName}.title`;
        const textTranslationKey = `literal.CustomLiterals.${actionName}.text`;
        if (i18n.keyExists(titleTranslationKey) && i18n.keyExists(textTranslationKey)) {
            setPopupResult({
                title: i18n.t(titleTranslationKey as TTranslationKey),
                text: i18n.t(textTranslationKey as TTranslationKey),
            });
        } else {
            // reloads the detail to make sure the layouts are reloaded
            setBookmark(undefined);
            history.replace(location.pathname);
        }
    };

    if (errorMessage) {
        return <ErrorMessage>{errorMessage}</ErrorMessage>;
    }

    if (!bookmark) {
        return (
            <>
                <LoadingPanel message={i18n.t('literal.CustomLiterals.DataBrowser.LoadingData')} />
                <Popup
                    visible={detailNotFound}
                    title={i18n.t('literal.CustomLiterals.DataBrowser.Detail.NotFound.Title')}
                    showCloseButton={false}
                    closeOnOutsideClick={false}
                    dragEnabled={false}
                    height={250}
                    width={375}
                >
                    {i18n.t('literal.CustomLiterals.DataBrowser.Detail.NotFound.Text')}
                    <PopupButtonBar>
                        <Button
                            text={i18n.t('literal.CustomLiterals.Confirm')}
                            type={'default'}
                            onClick={() => {
                                setDetailNotFound(false);
                                const listPath = routeHelper.getUrl(
                                    activeCrmContext.context,
                                    currentSiteMapItem && currentSiteMapItem.path
                                );
                                history.replace(listPath);
                            }}
                        />
                    </PopupButtonBar>
                </Popup>
                <Popup
                    visible={noDetailLayoutFound}
                    title={i18n.t('literal.CustomLiterals.DataBrowser.Detail.LayoutNotFound.Title')}
                    showCloseButton={false}
                    closeOnOutsideClick={false}
                    dragEnabled={false}
                    height={250}
                    width={375}
                >
                    {i18n.t('literal.CustomLiterals.DataBrowser.Detail.LayoutNotFound.Text', {
                        entity: entityName,
                    })}
                    <PopupButtonBar>
                        <Button
                            text={i18n.t('literal.CustomLiterals.Confirm')}
                            type={'default'}
                            onClick={() => {
                                setNoDetailLayoutFound(false);
                                const listPath = routeHelper.getUrl(activeCrmContext.context);
                                history.replace(listPath);
                            }}
                        />
                    </PopupButtonBar>
                </Popup>
                <Popup
                    visible={noListLayoutFound}
                    title={i18n.t('literal.CustomLiterals.DataBrowser.List.LayoutNotFound.Title')}
                    showCloseButton={false}
                    closeOnOutsideClick={false}
                    dragEnabled={false}
                    height={250}
                    width={375}
                >
                    {i18n.t('literal.CustomLiterals.DataBrowser.List.LayoutNotFound.Text', {
                        layout: listLayout ? listLayout.name : LayoutType.List,
                        entity: entityName,
                    })}
                    <PopupButtonBar>
                        <Button
                            text={i18n.t('literal.CustomLiterals.Confirm')}
                            type={'default'}
                            onClick={() => {
                                setNoListLayoutFound(true);
                                const listPath = routeHelper.getUrl(activeCrmContext.context);
                                history.replace(listPath);
                            }}
                        />
                    </PopupButtonBar>
                </Popup>
            </>
        );
    }

    if (popupResult) {
        return (
            <Popup
                visible={true}
                title={popupResult.title}
                showCloseButton={false}
                closeOnOutsideClick={false}
                dragEnabled={false}
                height={250}
                width={375}
            >
                {popupResult.text}
                <PopupButtonBar>
                    <Button
                        text={i18n.t('literal.CustomLiterals.Confirm')}
                        type={'default'}
                        onClick={() => {
                            setPopupResult(undefined);
                            // reloads the detail to make sure the layouts are reloaded
                            setBookmark(undefined);
                            history.replace(location.pathname);
                        }}
                    />
                </PopupButtonBar>
            </Popup>
        );
    }

    if (authorizationContext.isLoading) {
        return (
            <LoadingPanel
                message={i18n.t('literal.CustomLiterals.DataBrowser.LoadingAuthorizations')}
            />
        );
    }

    const preventDefaultHandler = (event: any) => {
        event.preventDefault();
        return false;
    };

    return (
        <div
            onDragEnter={preventDefaultHandler}
            onDragOver={preventDefaultHandler}
            onDrop={preventDefaultHandler}
        >
            <Breadcrumbs />
            <QuinoMetaPanel
                bookmark={bookmark}
                onBookmarkChanged={(newBookmark) => {
                    setBookmark(newBookmark);
                    updateUrl();
                    updateEntityActions();
                }}
                context={defaultDetailLayoutName}
                disableDrillDown={!drillDownEnabled}
            >
                <PerformaMetaPanelHeader
                    position={'Header'}
                    bookmark={bookmark}
                    listLayouts={listLayouts}
                    allowCreate={allowCreate}
                    onLayoutSelected={setListLayoutByName}
                    onNewRecord={onNewRecord}
                    hideTitle
                />
                <PerformaMetaPanelFooter
                    position={'Footer'}
                    entityActions={entityActions}
                    onActionExecuted={onActionExecuted}
                    context={{ layout: bookmark.layout, reloadCrmContextsAsync }}
                />
            </QuinoMetaPanel>
        </div>
    );
};

export const DataBrowser: React.ComponentType<IOwnProps> = withRouter(DataBrowserComponent);

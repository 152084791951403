import * as React from 'react';
import {
    IQuinoMetaPanelExtensionProps,
    ObjectBookmark,
    QuinoMetaPanelResetButton,
} from '@quino/ui';
import { PerformaMetaPanelEntityActionButton } from './PerformaMetaPanelEntityActionButton';
import { CustomFooterButton, ICustomFooterButton } from './CustomFooterButton';
import { ICustomAction } from '../../customActions/ICustomAction';
import { PerformaMetaPanelSaveButton } from './PerformaMetaPanelSaveButton';

type TStyles = {
    box: string;
    invalidMessage: string;
};

const styles: TStyles = require('./PerformaMetaPanelFooter.less');

interface IOwnProps {
    entityActions?: ICustomAction[];
    customButtons?: ICustomFooterButton[];
    onActionExecuted?: (actionName?: string) => void;
}

export const PerformaMetaPanelFooter: React.FunctionComponent<
    IQuinoMetaPanelExtensionProps & IOwnProps
> = (props) => {
    const { entityActions, customButtons, onActionExecuted, bookmark } = props;
    const generalErrors = (bookmark instanceof ObjectBookmark && bookmark.generalErrors) || [];
    return (
        <>
            {generalErrors.length > 0 && (
                <div className={styles.invalidMessage}>
                    {(generalErrors.length === 1 && generalErrors[0]) || (
                        <ul>
                            {generalErrors.map((errorMessage, index) => (
                                <li key={index}>{errorMessage}</li>
                            ))}
                        </ul>
                    )}
                </div>
            )}
            <div className={styles.box}>
                {customButtons &&
                    customButtons.map((buttonProps, index) => (
                        <CustomFooterButton
                            key={buttonProps.caption + '_custom_button_' + index}
                            buttonProps={buttonProps}
                        />
                    ))}
                {entityActions &&
                    entityActions.map((entityAction, index) => (
                        <PerformaMetaPanelEntityActionButton
                            key={entityAction.name + '_entity_action_button_' + index}
                            entityAction={entityAction}
                            onEntityActionExecuted={onActionExecuted}
                            {...props}
                        />
                    ))}
                <QuinoMetaPanelResetButton {...props} />
                <PerformaMetaPanelSaveButton {...props} />
            </div>
        </>
    );
};

import * as React from 'react';
import { useMemo } from 'react';
import { IQuinoComponentProps, ObjectBookmark, Translation } from '@quino/ui';
import { tKey, TTranslationKey } from '../../lang/TranslationKeys';
import { Markdown } from '../Util/Markdown/Markdown';
import { getAspectOrDefault, getMetaProperty } from '@quino/core';
import {
    ITranslationKeyAspect,
    TranslationKeyAspectIdentifier,
} from '../../meta/aspects/ITranslationKeyAspect';
import { ErrorMessage } from '../Util/ErrorMessage';
import { useI18n } from '../../lang/useI18n';

export const LocalizedLabel: React.FunctionComponent<IQuinoComponentProps<ObjectBookmark>> = (
    props
) => {
    const { element } = props;
    const metaProperty = getMetaProperty(element);

    const i18n = useI18n();

    const translationKeyAspect = useMemo(
        () => {
            return getAspectOrDefault<ITranslationKeyAspect>(
                element,
                TranslationKeyAspectIdentifier
            );
        },
        [metaProperty]
    );

    const translationKey = translationKeyAspect && translationKeyAspect.key;

    if (!translationKey) {
        return (
            <ErrorMessage>
                <Translation i18nKey={tKey('literal.CustomLiterals.LocalizedLabel.AspectError')} />
            </ErrorMessage>
        );
    }

    const customLiteralsKey = ('literal.CustomLiterals.' + translationKey) as TTranslationKey;

    if (!i18n.keyExists(customLiteralsKey)) {
        return (
            <ErrorMessage>
                <Translation
                    i18nKey={tKey('literal.CustomLiterals.LocalizedLabel.TranslationKeyError')}
                />
            </ErrorMessage>
        );
    }

    return (
        <Markdown
            key={props.element.name + 'localized_label'}
            style={
                translationKeyAspect && translationKeyAspect.csstextcolor
                    ? { color: translationKeyAspect.csstextcolor }
                    : undefined
            }
        >
            {i18n.t(customLiteralsKey)}
        </Markdown>
    );
};
